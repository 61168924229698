<template>
  <default-layout>
    <div class="overflow-visible">
      <div
        v-show="menuIsOpen"
        class="absolute inset-0 z-10"
        @click="menuIsOpen = false"
      />
      <main class="overflow-auto px-4 pt-2" style="height: calc(100vh - 70px)">
        <router-view
          v-if="country"
          :key="`${countryId}-${digitalClinicId}`"
          :timezone="countryTimezone"
        />

        <div
          v-if="!country"
          class="flex h-full w-full flex-col items-center justify-center"
        >
          <div class="mx-auto my-auto text-center text-gray-800">
            <base-spinner :loading="true" class="text-5xl" />
          </div>
        </div>
      </main>
    </div>
  </default-layout>
</template>
<script>
import { mapMutations, mapActions, mapState, mapGetters } from 'vuex';
import { capitalize } from 'lodash';
import { format, addDays } from 'date-fns';

export default {
  components: {},

  data() {
    return {
      menuIsOpen: false,
      isFetchingDigitalClinics: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['isAdmin']),
    ...mapState('admin', ['countryId']),
    ...mapGetters('admin/digital-clinic', {
      digitalClinic: 'getDigitalClinic',
      digitalClinics: 'getDigitalClinics',
      digitalClinicId: 'getDigitalClinicId',
    }),
    ...mapGetters('admin', ['countryTimezone', 'country']),
    ...mapGetters('admin/schedule', ['datesAreSet']),
    ...mapGetters('user', {
      user: 'getUser',
    }),
  },
  async mounted() {
    this.$root.$i18n.locale = 'uk';
    if (!this.datesAreSet) {
      this.setDatesToCurrent();
    }
  },
  methods: {
    ...mapMutations(['setTimeLineOpen']),
    ...mapMutations('admin/schedule', ['setDates']),
    ...mapActions('country', ['fetchCountries']),

    setDatesToCurrent() {
      this.setDates({
        fromDate: format(new Date(), 'yyyy-MM-dd'),
        toDate: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
      });
    },
    capitalize(str) {
      return capitalize(str);
    },
  },
};
</script>
<style lang="postcss">
.admin-menu-item-active {
  @apply bg-primary-lighter bg-opacity-70;
}
</style>
