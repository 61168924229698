var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("default-layout", [
    _c("div", { staticClass: "overflow-visible" }, [
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.menuIsOpen,
            expression: "menuIsOpen",
          },
        ],
        staticClass: "absolute inset-0 z-10",
        on: {
          click: function ($event) {
            _vm.menuIsOpen = false
          },
        },
      }),
      _c(
        "main",
        {
          staticClass: "overflow-auto px-4 pt-2",
          staticStyle: { height: "calc(100vh - 70px)" },
        },
        [
          _vm.country
            ? _c("router-view", {
                key: _vm.countryId + "-" + _vm.digitalClinicId,
                attrs: { timezone: _vm.countryTimezone },
              })
            : _vm._e(),
          !_vm.country
            ? _c(
                "div",
                {
                  staticClass:
                    "flex h-full w-full flex-col items-center justify-center",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "mx-auto my-auto text-center text-gray-800",
                    },
                    [
                      _c("base-spinner", {
                        staticClass: "text-5xl",
                        attrs: { loading: true },
                      }),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }